import React, { memo } from 'react';

type Props = {
  className: string,
  children: any,
}

const Tag = ({ className, children }: Props) => (
  <div className={`${className || ''} inline-block bg-light-purple text-blue border border-purple rounded-lg px-5 py-3`}>
    {children}
  </div>
);

export default memo(Tag);
