import React, { memo, useCallback, useMemo } from 'react';

import {
  iconCopy, iconLongArrowDown, iconLongArrowUp, iconTrash,
} from '../atoms/Icons';

type Props = {
  index: number,
  isLast: boolean,
  onMoveUp: Function,
  onMoveDown: Function,
  onDelete: Function,
  onDuplicate: Function,
}

const PageBuilderFrameToolbar = ({
  index, isLast, onMoveUp, onMoveDown, onDelete, onDuplicate,
}: Props) => {
  const handleClickMoveUp = useCallback(() => {
    onMoveUp(index, index - 1);
  }, [index, onMoveUp]);

  const handleClickMoveDown = useCallback(() => {
    onMoveDown(index, index + 2);
  }, [index, onMoveDown]);

  const handleClickDuplicate = useCallback(() => {
    onDuplicate(index);
  }, [index, onDuplicate]);

  const handleClickDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const buttons = useMemo(() => {
    const actions = [];
    if (index > 0) {
      actions.push({
        label: 'Move Up',
        icon: iconLongArrowUp,
        onClick: handleClickMoveUp,
      });
    }
    if (!isLast) {
      actions.push({
        label: 'Move Down',
        icon: iconLongArrowDown,
        onClick: handleClickMoveDown,
      });
    }
    actions.push(
      {
        label: 'Duplicate',
        icon: iconCopy,
        onClick: handleClickDuplicate,
      },
      {
        label: 'Delete',
        icon: iconTrash,
        className: 'text-error',
        onClick: handleClickDelete,
      },
    );
    return actions;
  }, [handleClickDelete, handleClickDuplicate, handleClickMoveDown, handleClickMoveUp, index, isLast]);

  return (
    <div className="flex flex-row items-center justify-end">
      {buttons.map((button) => (
        <button
          key={button.label}
          type="button"
          className={`button-light text-base mr-2 last:mr-0 hover:bg-light-purple transition-colors cursor-pointer
                      ${button.className || ''}`}
          onClick={button.onClick}
        >
          <span className="text-2xl mr-2">{button.icon}</span>
          <span>{button.label}</span>
        </button>
      ))}
    </div>
  );
};

export default memo(PageBuilderFrameToolbar);
