import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';

type Props = {
  className: string,
  lazy: boolean,
  onLoad: Function,
  contentfulGatsbyImage: {
    images: {
      sources: [{
        srcSet: string,
        sizes: string,
        type: string,
      }],
      fallback: {
        src: string,
        srcSet: string,
        sizes: string,
      }
    },
    layout: string,
    width: number,
    height: number,
  },
  width: string,
  height: string,
  url: string,
  alt: string,
  draggable: string,
}
const ContentfulPicture = ({
  className, contentfulGatsbyImage, lazy, onLoad, alt, url, width, height, draggable
}: Props) => {
  const loading = lazy ? 'lazy' : 'eager';
  const [loadingStatus, setLoadingStatus] = useState('');
  const $image = useRef();

  const handleLoadImg = useCallback(() => {
    setLoadingStatus('');
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  useEffect(() => { // Workaround for react on load
    if ($image.current?.complete) setLoadingStatus('');
  }, []);

  if (contentfulGatsbyImage?.images) {
    const { sources, fallback } = contentfulGatsbyImage.images;
    return (
      <picture className={`${loadingStatus}`}>
        {sources.map((source, idx) => (
          <source
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            srcSet={source.srcSet}
            type={source.type}
            sizes={source.sizes}
          />
        ))}
        {fallback
          && (
          <>
            <source srcSet={fallback.srcSet} sizes={fallback.sizes} />
            <img
              className={className}
              src={fallback.src}
              alt={alt}
              loading={loading}
              onLoad={handleLoadImg}
              ref={$image}
              height={height}
              width={width}
              draggable={draggable}
            />
          </>
          )}
      </picture>
    );
  }
  if (url) {
    return (
      <img
        className={className}
        src={url}
        alt={alt}
        loading={loading}
        onLoad={handleLoadImg}
        ref={$image}
        draggable={draggable}
      />
    );
  }
  return null;
};

export default memo(ContentfulPicture);
