import React, {
  memo, useCallback, useRef, useState,
} from 'react';

import { iconFileExport } from '../atoms/Icons';
import useClickOutside from '../../hooks/useClickOutside';
import ButtonExportFramesToWebflow from '../atoms/ButtonExportFramesToWebflow';

type Props = {
  frames: [],
}

const ToolbarExport = ({ frames }: Props) => {
  const ref = useRef(null);
  const [showExports, setShowExports] = useState(false);
  const handleClickToggleExports = useCallback(() => setShowExports((oldState) => !oldState), []);
  const handleClickOutside = useCallback(() => setShowExports(false), []);
  useClickOutside(ref, handleClickOutside, !showExports);
  return (
    <div ref={ref} className="relative">
      <button
        type="button"
        className={`button-gray ${showExports ? 'button-gray--active' : ''}`}
        onClick={handleClickToggleExports}
      >
        <span className="button-icon">{iconFileExport}</span>
        <span>Export</span>
      </button>
      {showExports && (
        <div className="absolute top-full border border-dark-gray-3 z-50 right-0 w-[330px] rounded
                        shadow-exports bg-light-gray-2 px-6 py-9 text-dark-gray"
        >
          <h3 className="text-xs font-bold mb-10">Select Format</h3>
          <ButtonExportFramesToWebflow frames={frames} />
        </div>
      )}
    </div>
  );
};

export default memo(ToolbarExport);
