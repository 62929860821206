import { v1 as uuid } from 'uuid';

import getWebflowPageWrapper from './getWebflowPageWrapper';

const generateIdFromPrefix = (prefix, number) => `${prefix}${number > 1 ? `-${number}` : ''}`;
const getNextAvailableIdFromObject = (obj, prefix, number) => {
  let incrementalNumber = number;
  let id = generateIdFromPrefix(prefix, incrementalNumber);
  while (obj[id]) {
    incrementalNumber += 1;
    id = generateIdFromPrefix(prefix, incrementalNumber);
  }
  return { id, number: incrementalNumber };
};

const transformDuplicatesToUniqueNode = (hash, nodes) => {
  const modifiedNodesIds = {};
  nodes.forEach((node) => {
    if (hash[node._id]) {
      // console.warn(`found existing node ${node._id} ${node.type}`);
      const newNode = {
        ...node,
        _id: uuid(),
      };
      modifiedNodesIds[node._id] = newNode._id;
      hash[newNode._id] = newNode;
      return;
    }
    hash[node._id] = node;
  });
  return modifiedNodesIds;
};
const transformDuplicatesToUniqueInteractions = (prevHash, interactions, prefix) => {
  const modifiedInteractions = {};
  const hash = {
    ...prevHash,
  };
  let interactionGeneratedId = 1;
  interactions.forEach((interaction) => {
    if (hash[interaction.id]) {
      // console.warn(`found existing node ${interaction.id}`);
      const { id: newId, number } = getNextAvailableIdFromObject(hash, prefix, interactionGeneratedId);
      interactionGeneratedId = number;
      const newInteraction = {
        ...interaction,
        id: newId,
      };
      modifiedInteractions[interaction.id] = newInteraction.id;
      hash[newInteraction.id] = newInteraction;
      return;
    }
    hash[interaction.id] = interaction;
  });
  return { hash, modifiedInteractions };
};

const generateWebflowJSONFromFrames = (frames) => {
  const base = getWebflowPageWrapper();
  const baseNode = base.payload.nodes[0];
  const baseStyle = base.payload.styles[0];

  const hash = {
    nodes: {},
    styles: {},
    assets: {},
    ix2: {
      interactions: {},
      events: {},
      actionLists: {},
    },
  };
  hash.nodes[baseNode._id] = baseNode;
  hash.styles[baseStyle._id] = baseStyle;

  frames.forEach((frame) => {
    const webflowEl = JSON.parse(frame.webflowComponent.internal.content);
    const { payload } = webflowEl;

    const modifiedNodesIds = transformDuplicatesToUniqueNode(hash.nodes, payload.nodes);
    const nodesWithoutDuplicate = [];
    payload.nodes.forEach((node) => {
      const buildNode = { ...node };
      const modifiedNodeId = modifiedNodesIds[node._id];
      if (modifiedNodeId) {
        buildNode._id = modifiedNodeId;
      }
      if (node.children) {
        buildNode.children = node.children.reduce((childs, childNodeId) => {
          const modifiedChildNodeId = modifiedNodesIds[childNodeId];
          childs.push(modifiedChildNodeId || childNodeId);
          return childs;
        }, []);
      }
      hash.nodes[buildNode._id] = buildNode;
      nodesWithoutDuplicate.push(buildNode);
    });
    const firstNode = nodesWithoutDuplicate[0];
    if (firstNode) {
      baseNode.children.push(firstNode._id);
    }
    base.payload.nodes.push(...nodesWithoutDuplicate);

    payload.styles.forEach((style) => {
      if (hash.styles[style._id]) {
        //   console.warn(`found existing style ${style._id} ${style.type}`);
        return;
      }
      hash.styles[style._id] = style;
      base.payload.styles.push(style);
    });

    payload.assets.forEach((asset) => {
      if (hash.assets[asset._id]) {
        //     console.warn(`found existing asset ${asset._id} ${asset.type}`);
        return;
      }
      hash.assets[asset._id] = asset;
      base.payload.assets.push(asset);
    });

    base.payload.ix1.push(...payload.ix1);

    const interactions = transformDuplicatesToUniqueInteractions(hash.ix2.interactions, payload.ix2.interactions, 'i');
    hash.ix2.interactions = { ...hash.ix2.interactions, ...interactions.hash };
    const events = transformDuplicatesToUniqueInteractions(hash.ix2.events, payload.ix2.events, 'e');
    hash.ix2.events = { ...hash.ix2.events, ...events.hash };
    const actionLists = transformDuplicatesToUniqueInteractions(hash.ix2.actionLists, payload.ix2.actionLists, 'a');
    hash.ix2.actionLists = { ...hash.ix2.actionLists, ...actionLists.hash };

    const modifiedInteractions = {
      interactions: interactions.modifiedInteractions,
      events: events.modifiedInteractions,
      actionLists: actionLists.modifiedInteractions,
    };

    const interactionsWithoutDuplicates = [];
    payload.ix2.interactions.forEach((interaction) => {
      const buildInteraction = { ...interaction };
      const modifiedInteractionId = modifiedInteractions.interactions[interaction.id];
      if (modifiedInteractionId) {
        buildInteraction.id = modifiedInteractionId;
      }
      if (interaction.eventIds) {
        buildInteraction.eventIds = buildInteraction.eventIds.map((eventId) => {
          const modifiedEventId = modifiedInteractions.events[eventId];
          return modifiedEventId || eventId;
        });
      }
      if (interaction.target) {
        const targetIds = interaction.target.split('|');
        buildInteraction.target = targetIds.map((nodeId) => {
          if (!hash.nodes[nodeId]) return nodeId;
          const modifiedNodeId = modifiedNodesIds[nodeId];
          return modifiedNodeId || nodeId;
        }).join('|');
      }
      hash.ix2.interactions[buildInteraction.id] = buildInteraction;
      interactionsWithoutDuplicates.push(buildInteraction);
    });
    base.payload.ix2.interactions.push(...interactionsWithoutDuplicates);

    const eventsWithoutDuplicates = [];
    payload.ix2.events.forEach((event) => {
      const buildEvent = { ...event };
      const modifiedEventId = modifiedInteractions.events[event.id];
      if (modifiedEventId) {
        buildEvent.id = modifiedEventId;
      }
      if (event.action?.config) {
        const { autoStopEventId, actionListId } = event.action.config;
        if (autoStopEventId) {
          event.action.config.autoStopEventId = modifiedInteractions.events[autoStopEventId] || autoStopEventId;
        }
        if (actionListId) {
          event.action.config.actionListId = modifiedInteractions.actionLists[actionListId] || actionListId;
        }
      }
      if (event.target) {
        const targetIds = event.target.id.split('|');
        buildEvent.target.id = targetIds.map((nodeId) => {
          if (!hash.nodes[nodeId]) return nodeId;
          const modifiedNodeId = modifiedNodesIds[nodeId];
          return modifiedNodeId || nodeId;
        }).join('|');
      }
      if (event.targets) {
        event.targets.map((target) => {
          const newTarget = { ...target };
          const targetIds = target.id.split('|');
          newTarget.id = targetIds.map((nodeId) => {
            if (!hash.nodes[nodeId]) return nodeId;
            const modifiedNodeId = modifiedNodesIds[nodeId];
            return modifiedNodeId || nodeId;
          }).join('|');
          return newTarget;
        });
      }
      hash.ix2.events[buildEvent.id] = buildEvent;
      eventsWithoutDuplicates.push(buildEvent);
    });
    base.payload.ix2.events.push(...eventsWithoutDuplicates);

    const actionListsWithoutDuplicates = [];
    payload.ix2.actionLists.forEach((actionList) => {
      const buildActionList = { ...actionList };
      const modifiedActionListId = modifiedInteractions.actionLists[actionList.id];
      if (modifiedActionListId) {
        buildActionList.id = modifiedActionListId;
      }
      if (actionList.actionItemGroups) {
        buildActionList.actionItemGroups = actionList.actionItemGroups.map((actionItemGroup) => {
          const newActionItemGroup = { ...actionItemGroup };
          if (newActionItemGroup.actionItems) {
            newActionItemGroup.actionItems = newActionItemGroup.actionItems.map((actionItem, idx) => {
              const newActionItem = { ...actionItem };
              if (modifiedActionListId) {
                const counter = idx + 1;
                newActionItem.id = `${modifiedActionListId}-n${counter > 1 ? `-${counter}` : ''}`;
              }
              if (actionItem.target) {
                const targetIds = newActionItem.target.nodeId.split('|');
                newActionItem.target.nodeId = targetIds.map((nodeId) => {
                  if (!hash.nodes[nodeId]) return nodeId;
                  const modifiedNodeId = modifiedNodesIds[nodeId];
                  return modifiedNodeId || nodeId;
                }).join('|');
              }
              return newActionItem;
            });
          }
          return newActionItemGroup;
        });
      }
      hash.ix2.actionLists[buildActionList.id] = buildActionList;
      actionListsWithoutDuplicates.push(buildActionList);
    });
    base.payload.ix2.actionLists.push(...actionListsWithoutDuplicates);
  });
  return base;
};

export default generateWebflowJSONFromFrames;
