import React, { memo, useCallback, useState } from 'react';

type Props = {
  placeholder: string,
  onChange: Function,
  iconRight: any,
}

const Input = ({ iconRight, placeholder, onChange }: Props) => {
  const [isFocus, setIsFocus] = useState(false);
  const handleFocus = useCallback((newState) => () => setIsFocus(newState), []);
  return (
    <div className={`${isFocus ? 'border-blue' : 'border-light-gray-5'}
                     border rounded px-10 pt-3 pb-2 bg-white flex flex-row items-center
    `}
    >
      <input
        className="placeholder:text-dark-gray-4 text-black w-full outline-none"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocus(true)}
        onBlur={handleFocus(false)}
      />
      {iconRight && <span className="ml-2 text-2xl text-dark-gray-2">{iconRight}</span>}
    </div>
  );
};

export default memo(Input);
