import React, { memo } from 'react';

import DraggableCardFrame from './DraggableCardFrame';

type Props = {
  frames: [{
    id: string,
  }],
  onAddFrame: Function,
}

const ListFrames = ({ frames, onAddFrame }: Props) => (
  <div className="pb-8">
    {frames.length > 0 ? frames.map((frame, idx) => (
      <DraggableCardFrame
        className="mb-7 mx-5 dragfix"
        key={frame.id}
        frame={frame}
        index={idx}
        onAdd={onAddFrame}
      />
    )) : <div className="mx-5 text-dark-gray-2">No frames found</div>}
  </div>
);

export default memo(ListFrames);
