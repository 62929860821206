import React, {
  memo, useCallback, useEffect, useRef,
} from 'react';

import { isBrowser } from '../../helpers/utils';

type Props = {
  zoom: number,
  onChange: Function,
}

const ToolbarZoom = ({ zoom, onChange }: Props) => {
  const refZoom = useRef(zoom);
  const handleClickMinus = useCallback(() => {
    let newZoom = zoom - 5;
    if (newZoom < 25) {
      newZoom = 25;
    }
    onChange(newZoom);
  }, [onChange, zoom]);
  const handleClickPlus = useCallback(() => {
    let newZoom = zoom + 5;
    if (newZoom > 150) {
      newZoom = 150;
    }
    onChange(newZoom);
  }, [onChange, zoom]);
  const handleMouseWheel = useCallback((ev) => {
    if (!(ev.ctrlKey || ev.metaKey)) return false;
    ev.preventDefault();
    let newZoom = refZoom.current;
    if (ev.wheelDelta < 0) {
      newZoom = newZoom + 5 > 150 ? 150 : newZoom + 5;
    } else {
      newZoom = newZoom - 5 < 25 ? 25 : newZoom - 5;
    }
    onChange(newZoom);
    return true;
  }, [onChange]);
  useEffect(() => {
    refZoom.current = zoom;
  }, [zoom]);
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('mousewheel', handleMouseWheel, { passive: false });
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('mousewheel', handleMouseWheel);
      }
    };
  }, [handleMouseWheel]);
  return (
    <div className="flex flex-row items-center text-light-gray-3 text-base px-3 bg-dark-gray mr-2 rounded">
      <button
        type="button"
        className="px-2"
        onClick={handleClickMinus}
      >
        -
      </button>
      <div className="w-16 text-center">
        {`${zoom}%`}
      </div>
      <button
        type="button"
        className="px-2"
        onClick={handleClickPlus}
      >
        +
      </button>
    </div>
  );
};

export default memo(ToolbarZoom);
