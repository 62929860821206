const isElementVisibleInScrollableContainer = (container, ele, zoom, checkIfVisiblePartially = false) => {
  const zoomModifier = zoom / 100;
  const eleTop = ele.offsetTop * zoomModifier;
  const eleBottom = eleTop + (ele.clientHeight * zoomModifier);

  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  const partially = checkIfVisiblePartially
    && ((eleTop < containerTop && containerTop < eleBottom)
      || (eleTop < containerBottom && containerBottom < eleBottom));

  return ((eleTop >= containerTop && eleBottom <= containerBottom) || partially);
};

export default isElementVisibleInScrollableContainer;
