import React, { memo, useCallback } from 'react';

import ContentfulPicture from './ContentfulPicture';
import { iconCopy } from './Icons';

type Props = {
  className: string,
  isDragging: boolean,
  frame: {
    id: string,
    name: string,
    image: {
      gatsbyImageData: string,
    }
  },
  onAdd: Function,
}

const CardFrame = React.forwardRef(({
  className, frame, isDragging, onAdd,
}: Props, ref) => {
  const { name, image } = frame;
  const handleClickAdd = useCallback(() => onAdd(frame), [frame, onAdd]);
  return (
    <div
      ref={ref}
      className={`${className || ''} cursor-pointer group`}
    >
      <div className="relative pt-[72.22%]">
        <ContentfulPicture
          className={`absolute top-0 left-0 h-full object-contain bg-light-gray-4 px-4 \
                  rounded-xl transition group-hover:shadow-md ${isDragging ? 'opacity-40' : ''} `}
          lazy
          height="260"
          width="372"
          contentfulGatsbyImage={image.gatsbyImageData}
        />
        <div className="hidden group-hover:block absolute bottom-3 right-3">
          <button
            className="button border py-3 px-5 border-dark-gray-2 rounded text-base bg-white hover:bg-light-purple"
            onClick={handleClickAdd}
          >
            <span className="text-2xl mr-2">{iconCopy}</span>
            <span>Add</span>
          </button>
        </div>
      </div>
      <div className="mt-5 font-semibold text-center">
        {name}
      </div>
    </div>
  );
});

export default memo(CardFrame);
