import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { useDrop } from 'react-dnd';

import ListFrames from '../molecules/ListFrames';
import Input from '../atoms/Input';
import Dropdown from '../atoms/Dropdown';
import { BUILDER_SECTION } from '../../constants/dnd-types';
import { debounce } from '../../helpers/utils';
import DropdownCategory from '../molecules/DropdownCategory';
import ListHoritonzalTags from '../molecules/ListHoritonzalTags';
import { iconZoom } from '../atoms/Icons';

type Props = {
  frames: [],
  onDropFrame: Function,
  onAddFrameAtEnd: Function,
}

const FrameLibrary = ({ frames, onDropFrame, onAddFrameAtEnd }: Props) => {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [query, setQuery] = useState('');
  const [{ isOver }, drop] = useDrop({
    accept: BUILDER_SECTION,
    drop: (item) => {
      onDropFrame(item.index);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleChangeQuery = useCallback((e) => setQuery(e.target.value), []);
  const handleChangeCategory = useCallback((newCategories) => setFilteredCategories(newCategories), []);

  const debounceHandleChangeQuery = useMemo(() => debounce(handleChangeQuery, 300), [handleChangeQuery]);
  const categoriesTags = useMemo(() => filteredCategories.map((category) => category.label), [filteredCategories]);
  const bgColor = isOver ? 'bg-gradiant-1' : 'bg-light-gray-2';

  const framesFilteredByCategories = useMemo(() => (filteredCategories.length === 0 ? frames
    : frames.reduce((acc, frame) => {
      const hasAtLeastOneCategory = frame.categories.some(
        (frameCat) => filteredCategories.some((filtCat) => filtCat.value === frameCat.slug),
      );
      if (hasAtLeastOneCategory) {
        acc.push(frame);
      }
      return acc;
    }, [])), [frames, filteredCategories]);
  const framesFilteredByName = useMemo(() => {
    if (!query) return framesFilteredByCategories;
    const lowercaseQuery = query.toLowerCase();
    return framesFilteredByCategories.filter((frame) => frame.name.toLowerCase().indexOf(lowercaseQuery) > -1);
  }, [query, framesFilteredByCategories]);
  return (
    <div ref={drop} className={`${bgColor} h-full pt-5 flex flex-col`}>
      <div className="px-5">
        <Input placeholder="Search by name" onChange={debounceHandleChangeQuery} iconRight={iconZoom} />
        <DropdownCategory className="mt-4" onChange={handleChangeCategory} />
        {categoriesTags.length > 0 && <ListHoritonzalTags className="mt-7" title="Categories" tags={categoriesTags} />}
      </div>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 bottom-0 w-full overflow-y-auto pt-10">
          <ListFrames
            frames={framesFilteredByName}
            onAddFrame={onAddFrameAtEnd}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FrameLibrary);
