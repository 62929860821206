import React, { memo } from 'react';

import ToolbarExport from '../molecules/ToolbarExport';
import ToolbarZoom from '../molecules/ToolbarZoom';

type Props = {
  frames: [],
  zoom: number,
  onChangeZoom: Function,
}

const PageBuilderToolbar = ({ frames, zoom, onChangeZoom }: Props) => (
  <div className="flex flex-row justify-end bg-dark-gray-2 text-white px-10 py-1">
    <ToolbarZoom zoom={zoom} onChange={onChangeZoom} />
    <ToolbarExport frames={frames} />
  </div>
);

export default memo(PageBuilderToolbar);
