import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

type Props = {
  seo: {
    title: string,
    description: string,
    contentType: 'website' | 'article',
    image: string,
    externalImage: boolean,
    url: string,
  }
}
const HeadMeta = ({ seo = {} }: Props) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `);
  const {
    title = null, description = '', contentType = 'website', image, url, externalImage,
  } = seo;
  const { siteUrl } = site.siteMetadata;
  const shareImage = image || '/static/share-image.png';

  const meta = {
    siteUrl,
    title: title || site.siteMetadata.title,
    type: contentType,
    description: description || site.siteMetadata.description,
    url: `${siteUrl}${url || ''}`,
    image: externalImage && image ? image : `${siteUrl}${shareImage}`,
  };
  return (
    <Helmet>
      <html lang={meta.language} />
      <link rel="canonical" href={meta.url} />
      <meta property="image" content={meta.image} />
      <meta name="robots" content="index, follow" />

      <meta name="description" content={meta.description} />

      <meta name="og:type" prefix="og: http://ogp.me/ns#" content={meta.type} />
      <meta property="og:title" prefix="og: http://ogp.me/ns#" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:url" prefix="og: http://ogp.me/ns#" content={meta.url} />

      <meta name="twitter:card" content="summary" />
      {/*
      <meta name="twitter:creator" content={meta.twitterUsername} />
      <meta name="twitter:site" content={meta.twitterUsername} />
*/}
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <title>{meta.title}</title>
      {/*        <script type="application/ld+json">
        {JSON.stringify(generateSchemaOrg(t, meta))}
      </script> */}
    </Helmet>
  );
};

export default HeadMeta;
