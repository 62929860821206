import React from 'react';

export const iconFileExport = (
  <svg width="1em" height="0.96em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 1H15.6642L21.25 6.58579V21C21.25 22.1046 20.3546 23 19.25 23H5.25C4.14543 23 3.25 22.1046 3.25 21V3C3.25 1.89543 4.14543 1 5.25 1ZM13.25 3H5.25V21H19.25V9H15.25C14.1454 9 13.25 8.10457 13.25 7V3ZM15.25 3.41421V7H18.8358L15.25 3.41421ZM11.9571 15.2929L10.5429 16.7071L6.83579 13L10.5429 9.29289L11.9571 10.7071L9.66421 13L11.9571 15.2929ZM17.6642 15L13.9571 11.2929L12.5429 12.7071L14.8358 15L12.5429 17.2929L13.9571 18.7071L17.6642 15Z"
      fill="currentColor"
    />
  </svg>
);

export const iconOptions = (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1668 12.8331C12.9923 12.8331 11.1652 11.3459 10.6472 9.33309H2.3335V6.99976H10.6472C11.1652 4.98699 12.9923 3.49976 15.1668 3.49976C17.7442 3.49976 19.8335 5.58909 19.8335 8.16642C19.8335 10.7438 17.7442 12.8331 15.1668 12.8331ZM22.1668 6.99976H25.6668V9.33309H22.1668V6.99976ZM9.3335 24.4998C7.15902 24.4998 5.3319 23.0125 4.81385 20.9998H2.3335V18.6664H4.81385C5.3319 16.6537 7.15902 15.1664 9.3335 15.1664C11.9108 15.1664 14.0002 17.2558 14.0002 19.8331C14.0002 22.4104 11.9108 24.4998 9.3335 24.4998ZM16.3335 20.9998H25.6668V18.6664H16.3335V20.9998ZM11.6668 19.8331C11.6668 21.1218 10.6222 22.1664 9.3335 22.1664C8.04483 22.1664 7.00016 21.1218 7.00016 19.8331C7.00016 18.5444 8.04483 17.4998 9.3335 17.4998C10.6222 17.4998 11.6668 18.5444 11.6668 19.8331ZM17.5002 8.16642C17.5002 9.45509 16.4555 10.4998 15.1668 10.4998C13.8782 10.4998 12.8335 9.45509 12.8335 8.16642C12.8335 6.87776 13.8782 5.83309 15.1668 5.83309C16.4555 5.83309 17.5002 6.87776 17.5002 8.16642Z"
      fill="currentColor"
    />
  </svg>
);

export const iconArrowUp = (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5085 19.4913L24.1584 17.8414L14.0001 7.68312L3.8418 17.8414L5.49171 19.4913L14.0001 10.983L22.5085 19.4913Z"
      fill="currentColor"
    />
  </svg>
);

export const iconArrowDown = (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5085 8.5083L24.1584 10.1582L14.0001 20.3165L3.8418 10.1582L5.49171 8.5083L14.0001 17.0167L22.5085 8.5083Z"
      fill="currentColor"
    />
  </svg>
);

export const iconZoom = (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3332 20.9998C21.4878 20.9998 25.6665 16.8211 25.6665 11.6665C25.6665 6.51181 21.4878 2.33313 16.3332 2.33313C11.1785 2.33313 6.99984 6.51181 6.99984 11.6665C6.99984 13.8233 7.73143 15.8092 8.96 17.3897L2.67488 23.6748L4.32479 25.3248L10.6099 19.0396C12.1904 20.2682 14.1763 20.9998 16.3332 20.9998ZM9.33317 11.6665C9.33317 15.5325 12.4672 18.6665 16.3332 18.6665C20.1992 18.6665 23.3332 15.5325 23.3332 11.6665C23.3332 7.80047 20.1992 4.66646 16.3332 4.66646C12.4672 4.66646 9.33317 7.80047 9.33317 11.6665Z"
      fill="currentColor"
    />
  </svg>
);

export const iconLongArrowUp = (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / arrows / arrow-top">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.41412V21.9999H11V5.41412L4.70712 11.707L3.29291 10.2928L12 1.58569L20.7071 10.2928L19.2929 11.707L13 5.41412Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const iconLongArrowDown = (
  <svg width="1em" height="0.96em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / arrows / arrow-bottom">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 18.5858V3H13.5V18.5858L19.7929 12.2929L21.2071 13.7071L12.5 22.4142L3.79291 13.7071L5.20712 12.2929L11.5 18.5858Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const iconCopy = (
  <svg width="1em" height="0.96em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 2H20.5C21.6523 2 22.5 2.84772 22.5 4V14C22.5 15.1523 21.6523 16 20.5 16H16.5V20C16.5 21.1523 15.6523 22 14.5 22H4.5C3.34772 22 2.5 21.1523 2.5 20V10C2.5 8.84772 3.34772 8 4.5 8H8.5V4C8.5 2.84772 9.34772 2 10.5 2ZM8.5 10H4.5V20H14.5V16H10.5C9.34772 16 8.5 15.1523 8.5 14V10ZM10.5 4V14H20.5V4H10.5Z"
      fill="currentColor"
    />
  </svg>
);

export const iconTrash = (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / basic / trash">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1H15C16.1046 1 17 1.89543 17 3V4H20C21.1046 4 22 4.89543 22 6V8C22 9.10457 21.1046 10 20 10H19.9199L19 21C19 22.1046 18.1046 23 17 23H7C5.89543 23 5 22.1046 5.00345 21.083L4.07987 10H4C2.89543 10 2 9.10457 2 8V6C2 4.89543 2.89543 4 4 4H7V3C7 1.89543 7.89543 1 9 1ZM4 6H7H17H20V8H4V6ZM6.08649 10H17.9132L17.0035 20.917L17 21H7L6.08649 10ZM15 3V4H9V3H15Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
