import React, { memo } from 'react';
import { useDrop } from 'react-dnd';

import { BUILDER_SECTION, LIBRARY_SECTION } from '../../constants/dnd-types';
import DraggablePageBuilderFrame from '../molecules/DraggablePageBuilderFrame';

type Props = {
  frames: [{
    name: string,
    image: {
      gatsbyImageData: {}
    }
  }],
  percentZoom: number,
  onDrop: Function,
  onReorder: Function,
  onDeleteFrame: Function,
  onDuplicateFrame: Function,
}

const PageBuilder = ({
  frames, percentZoom, onDrop, onReorder, onDeleteFrame, onDuplicateFrame
}: Props) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [LIBRARY_SECTION, BUILDER_SECTION],
    drop: (item, monitor) => {
      const type = monitor.getItemType();
      if (type === LIBRARY_SECTION) {
        onDrop(item, type, frames.length);
      } else if (type === BUILDER_SECTION) {
        onReorder(item.index, frames.length);
      }
    },
    canDrop: (item, monitor) => {
      const type = monitor.getItemType();
      if (type === BUILDER_SECTION) {
        if (item.index === frames.length - 1) {
          return false;
        }
      }
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const heightDropzone = frames.length > 0 ? 'h-[100px]' : 'h-[400px]';
  const styleZoom = { transform: `scale(${percentZoom / 100})`, transformOrigin: '0 0' };
  return (
    <div className="relative bg-light-gray h-full">
      <div id="builder" className="absolute top-0 bottom-0 left-0 w-full overflow-auto py-16">
        <div className="max-w-screen-lg m-auto" style={styleZoom}>
          <h1 className="mb-4 font-semibold">Frames</h1>
          <div className="bg-white rounded-lg">
            {frames.map((frame, idx) => {
              const key = `builder-frame-${idx}-${frame.id}`;
              return (
                <DraggablePageBuilderFrame
                  key={key}
                  id={key}
                  index={idx}
                  isLast={idx === frames.length - 1}
                  frame={frame}
                  onDragOver={onReorder}
                  onMoveUp={onReorder}
                  onMoveDown={onReorder}
                  onDrop={onDrop}
                  onDelete={onDeleteFrame}
                  onDuplicate={onDuplicateFrame}
                />
              );
            })}
            <div
              ref={drop}
              className={`${heightDropzone} first:rounded-t-lg rounded-b-lg \
                          ${isOver && canDrop ? 'bg-gradiant-1' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PageBuilder);
