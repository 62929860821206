import React, { memo } from 'react';

import Header from '../molecules/Header';
import HeadMeta from '../atoms/HeadMeta';

type Props = {
  children: any,
  seo: {},
  hideSmallDevices: boolean,
}
const Layout = ({
  children, seo, hideSmallDevices,
}: Props) => (
  <>
    <HeadMeta seo={seo} />
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex flex-col flex-1 text-dark-gray" id="content">{children}</main>
    </div>
    {hideSmallDevices && (
      <div className="fixed top-0 left-0 bottom-0 right-0 bg-light-gray z-50 hidden touch:flex flex-col
                      items-center justify-center"
      >
        <div className="text-dark-gray font-semibold">Interfaces.co only works on desktop</div>
        <div className="text-dark-gray text-sm mt-1">Switch to a desktop to start building.</div>
      </div>
    )}
  </>
);

export default memo(Layout);
