import React, { memo, useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';

import generateWebflowJSONFromFrames from '../../helpers/generateWebflowJSONFromFrames';

type Props = {
  frames: [{
    webflowComponent: {
      internal: {
        content: string,
      }
    }
  }]
};

const ButtonExportFramesToWebflow = ({ frames }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const page = generateWebflowJSONFromFrames(frames);
      copy(JSON.stringify(page), {
        format: 'application/json',
      });
      setIsCopied(true);
    } catch (e) {
      console.error(e);
      setError('An error occured while generating webflow components');
    } finally {
      setLoading(false);
    }
  }, [frames]);
  return (
    <div>
      <button
        disabled={!frames.length}
        className="border border-dark-gray w-full text-xs font-bold py-4 rounded"
        type="button"
        onClick={handleClick}
      >
        {isLoading ? 'Generation...' : 'Export to Webflow'}
      </button>
      {error && <div className="mt-2 text-xs text-error">{error}</div>}
      {isCopied && (
      <div className="mt-2 text-xs text-success">
        Copied to clipboard, go to any webflow and paste on Body
      </div>
      )}
    </div>
  );
};

export default memo(ButtonExportFramesToWebflow);
