import React, { memo, useMemo } from 'react';
import { graphql } from 'gatsby';

import FrameBuilder from '../components/template/FrameBuilder';

type Props = {
  data: {
    allContentfulFrame: {
      edges: [{
        node: {},
      }]
    }
  }
}
const Index = ({ data }: Props) => {
  const frames = useMemo(() => data.allContentfulFrame.edges.map(({ node }) => node), [data]);
  return (
    <FrameBuilder frames={frames} />
  );
};

export default memo(Index);

export const query = graphql`
  query {
    allContentfulFrame(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          ...FrameFull
        }
      }
    }
  }
`;
