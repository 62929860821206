import React, {
  memo, useCallback, useRef, useState,
} from 'react';

import { handleKeyDownEnter } from '../../helpers/utils';
import useClickOutside from '../../hooks/useClickOutside';

import { iconArrowDown, iconArrowUp, iconOptions } from './Icons';

type Props = {
  className: string,
  multi: boolean,
  placeholder: string,
  onChange: Function,
  options: [{
    label: string,
    value: string,
  }],
}

const Dropdown = ({
  className, placeholder, multi, options, onChange,
}: Props) => {
  const ref = useRef(null);
  const [selectedOpt, setSelectedOpt] = useState(multi ? [] : null);
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleShowOptions = useCallback(() => setShowOptions((oldState) => !oldState), []);
  const handleCloseOptions = useCallback(() => setShowOptions(false), []);
  const handleClickOption = useCallback((newOpt) => () => {
    let newValue;
    if (multi) {
      newValue = [...selectedOpt];
      const idxOptValue = selectedOpt.findIndex((selOpt) => selOpt.value === newOpt.value);
      if (idxOptValue > -1) {
        newValue.splice(idxOptValue, 1);
      } else {
        newValue.push(newOpt);
      }
    } else {
      newValue = newOpt;
      setShowOptions(false);
    }
    setSelectedOpt(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }, [multi, onChange, selectedOpt]);
  useClickOutside(ref, handleCloseOptions, !showOptions);
  return (
    <div ref={ref} className={`${className || ''} relative`}>
      <div
        className={`border border-light-gray-5 rounded text-base w-full px-10 pt-3 pb-2 bg-white flex flex-row
                  items-center ${showOptions ? 'border-blue' : ''}`}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDownEnter(handleToggleShowOptions)}
        onClick={handleToggleShowOptions}
      >
        <div className="text-2xl text-dark-gray-2">{iconOptions}</div>
        <div className="flex-1 text-base px-2 text-dark-gray-4">{placeholder}</div>
        <div className="text-2xl text-dark-gray-2">{showOptions ? iconArrowUp : iconArrowDown}</div>
      </div>
      {showOptions
        && (
        <div className="absolute z-20 bg-white top-full left-0 w-full max-h-[238px] overflow-y-auto
                        border border-light-gray-5"
        >
          {options.map((opt) => {
            const isChecked = multi && selectedOpt.findIndex((selOpt) => selOpt.value === opt.value) > -1;
            return (
              <div
                role="button"
                onKeyDown={handleKeyDownEnter(handleClickOption(opt))}
                tabIndex={0}
                className="flex flex-row items-center px-10 py-4 border-b border-light-gray-5
                           last:border-0 cursor-pointer"
                key={opt.value}
                onClick={handleClickOption(opt)}
              >
                {multi && (
                  <span className={`h-5 w-5 border-2 mr-5 rounded-sm 
                    ${isChecked ? 'bg-blue border-blue' : 'bg-white border-light-gray-6'}`}
                  />
                )}
                <span className="text-base font-semibold">
                  {opt.label}
                </span>
              </div>
            );
          })}
        </div>
        )}
    </div>
  );
};

export default memo(Dropdown);
