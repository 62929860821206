import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Layout from '../organisms/Layout';
import FrameLibrary from '../organisms/FrameLibrary';
import PageBuilder from '../organisms/PageBuilder';
import PageBuilderToolbar from '../organisms/PageBuilderToolbar';
import { LIBRARY_SECTION } from '../../constants/dnd-types';
import isElementVisibleInScrollableContainer from '../../helpers/isElementVisibleInScrollableContainer';

type Props = {
  frames: [],
};

const FrameBuilder = ({ frames }: Props) => {
  const [pageFrames, setPageFrames] = useState([]);
  const [zoom, setZoom] = useState(100);
  const [frameToScroll, setFrameToScroll] = useState(false);
  const seo = useMemo(() => ({
    title: 'Interfaces',
    description: 'Build beautiful websites in hours vs days',
  }), []);

  const handleChangeZoom = useCallback((newZoom) => setZoom(newZoom), []);
  const handleDrop = useCallback((frame, type, position) => {
    if (type === LIBRARY_SECTION) {
      setPageFrames((oldFrames) => {
        const newFrames = [...oldFrames];
        newFrames.splice(position, 0, frame);
        return newFrames;
      });
    }
  }, []);
  const handleAddFrameAtEnd = useCallback((frame, type) => {
    const positionEnd = pageFrames.length;
    handleDrop(frame, type, positionEnd);
    setFrameToScroll({
      id: `#builder-frame-${positionEnd}-${frame.id}`,
      scrollIfPartial: true,
    });
  }, [handleDrop, pageFrames.length]);
  const handleRemoveFrame = useCallback((index) => {
    setPageFrames((oldFrames) => {
      const newFrames = [...oldFrames];
      newFrames.splice(index, 1);
      return newFrames;
    });
  }, []);
  const handleDuplicateFrame = useCallback((index) => {
    const newFrames = [...pageFrames];
    const frameToDuplicate = { ...pageFrames[index] };
    newFrames.splice(index, 0, frameToDuplicate);
    setPageFrames(newFrames);
    setFrameToScroll({
      id: `#builder-frame-${index + 1}-${frameToDuplicate.id}`,
      scrollIfPartial: true,
    });
  }, [pageFrames]);
  const handleReorderFrames = useCallback((dragIdx, hoverIdx) => {
    const dragItem = pageFrames[dragIdx];
    if (dragItem) {
      setPageFrames((oldPageFrames) => {
        const newPagesFrames = [...oldPageFrames];
        const [removedItem] = newPagesFrames.splice(dragIdx, 1);
        const newPos = hoverIdx <= dragIdx ? hoverIdx : hoverIdx - 1;
        newPagesFrames.splice(newPos, 0, removedItem);
        return newPagesFrames;
      });
    }
  }, [pageFrames]);

  useEffect(() => {
    if (frameToScroll) {
      const builderEl = document.querySelector('#builder');
      const frameEl = builderEl.querySelector(frameToScroll.id);
      const isVisible = isElementVisibleInScrollableContainer(builderEl, frameEl, zoom, !frameToScroll.scrollIfPartial);

      if (!isVisible) {
        builderEl.scrollTo({
          top: frameEl.offsetTop * (zoom / 100),
          behavior: 'smooth',
        });
      }
      setFrameToScroll(false);
    }
  }, [zoom, frameToScroll]);
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout seo={seo} hideSmallDevices>
        <PageBuilderToolbar frames={pageFrames} zoom={zoom} onChangeZoom={handleChangeZoom} />
        <div className="flex flex-1">
          <div className="max-w-[412px] w-[24%] shadow-page-builder-side z-10">
            <FrameLibrary
              frames={frames}
              onDropFrame={handleRemoveFrame}
              onAddFrameAtEnd={handleAddFrameAtEnd}
            />
          </div>
          <div className="flex-1">
            <PageBuilder
              percentZoom={zoom}
              frames={pageFrames}
              onDrop={handleDrop}
              onReorder={handleReorderFrames}
              onDeleteFrame={handleRemoveFrame}
              onDuplicateFrame={handleDuplicateFrame}
            />
          </div>
        </div>
      </Layout>
    </DndProvider>
  );
};

export default memo(FrameBuilder);
