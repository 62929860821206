import React, { memo, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Dropdown from '../atoms/Dropdown';

type Props = {
  className: string,
  onChange: Function,
}

const DropdownCategory = ({ className, onChange }: Props) => {
  const { allContentfulCategory } = useStaticQuery(graphql`
    query {
      allContentfulCategory(sort: {fields: name, order: ASC}) {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  `);
  const options = useMemo(() => allContentfulCategory.edges.map(({ node }) => ({
    label: node.name,
    value: node.slug,
  })), [allContentfulCategory]);
  return (
    <Dropdown
      className={className}
      multi
      placeholder="Filter by: Select"
      options={options}
      onChange={onChange}
    />
  );
};

export default memo(DropdownCategory);
