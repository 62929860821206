import React, { memo, useCallback } from 'react';
import { useDrag } from 'react-dnd';

import CardFrame from '../atoms/CardFrame';
import { LIBRARY_SECTION } from '../../constants/dnd-types';

type Props = {
  className: string,
  frame: {},
  onAdd: Function,
}

const DraggableCardFrame = ({ className, frame, onAdd }: Props) => {
  const [{ isDragging }, drag] = useDrag({
    type: LIBRARY_SECTION,
    item: frame,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const handleAdd = useCallback((frameData) => onAdd(frameData, LIBRARY_SECTION), [onAdd]);
  return (
    <CardFrame
      ref={drag}
      className={className}
      frame={frame}
      onAdd={handleAdd}
      isDragging={isDragging}
    />
  );
};

export default memo(DraggableCardFrame);
