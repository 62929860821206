import React, { memo } from 'react';

import Tag from '../atoms/Tag';

type Props = {
  className: string,
  title: string,
  tags: [string],
}

const ListHoritonzalTags = ({ className, tags, title }: Props) => (
  <div className={`${className || ''}`}>
    <h2 className="text-base font-semibold text-dark-gray-2">{title}</h2>
    <div className="mt-3 overflow-x-auto whitespace-nowrap">
      {tags.map((tag) => (
        <Tag className="mr-[6px] last:mr-0" key={tag}>{tag}</Tag>
      ))}
    </div>
  </div>
);

export default memo(ListHoritonzalTags);
