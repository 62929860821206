import React, { memo, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import ContentfulPicture from '../atoms/ContentfulPicture';
import { BUILDER_SECTION, LIBRARY_SECTION } from '../../constants/dnd-types';

import PageBuilderFrameToolbar from './PageBuilderFrameToolbar';

type Props = {
  frame: {
    id: string,
    image: {
      file: {
        url: string,
        details: {
          image: {
            width: number,
            height: number
          },
        },
      },
      gatsbyImageData: string,
    }
  },
  index: number,
  isLast: number,
  onDragOver: Function,
  onDrop: Function,
  onDelete: Function,
  onMoveUp: Function,
  onMoveDown: Function,
  onDuplicate: Function,
}

const DraggablePageBuilderFrame = ({
  index, isLast, frame, onDragOver, onDrop, onDelete, onMoveUp, onMoveDown, onDuplicate,
}: Props) => {
  const ref = useRef(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [LIBRARY_SECTION, BUILDER_SECTION],
    drop: (item, monitor) => {
      const type = monitor.getItemType();
      if (type === LIBRARY_SECTION) {
        onDrop(item, type, index);
      } else if (type === BUILDER_SECTION) {
        onDragOver(item.index, index);
      }
    },
    canDrop: (item, monitor) => {
      const type = monitor.getItemType();
      if (type === BUILDER_SECTION) {
        if (item.index === index || item.index === index - 1) {
          return false;
        }
      }
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: BUILDER_SECTION,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const opacity = isDragging ? 'opacity-40' : '';
  const roundedTop = index === 0 ? 'rounded-t-lg' : '';

  useEffect(() => {
    const img = new Image();

    const { url, details: { image: { width, height } } } = frame.image.file;
    const previewWidth = 500;
    const previewHeight = Math.floor((previewWidth * height) / width);

    const src = `https:${url}?w=${previewWidth}&h=${previewHeight}&q=50`;
    img.src = src;
    img.crossOrigin = 'anonymous';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = previewWidth;
    ctx.canvas.height = previewHeight;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
      img.src = canvas.toDataURL();
      preview(img);
    };
  }, [frame, preview]);
  return (
    <div
      id={`builder-frame-${index}-${frame.id}`}
      ref={ref}
      className={`group hover:outline hover:z-10 outline-blue relative cursor-pointer ${opacity} ${roundedTop}`}
    >
      {isOver && canDrop && <div className={`h-24 w-full bg-gradiant-1 ${roundedTop}`} />}
      <div className="relative">
        <div className="absolute right-2 top-2 z-10 hidden group-hover:block">
          <PageBuilderFrameToolbar
            index={index}
            isLast={isLast}
            onDelete={onDelete}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            onDuplicate={onDuplicate}
          />
        </div>
        <ContentfulPicture
          className={`w-full h-auto ${!isOver ? roundedTop : ''}`}
          contentfulGatsbyImage={frame.image.gatsbyImageData}
        />
      </div>
    </div>
  );
};

export default memo(DraggablePageBuilderFrame);
