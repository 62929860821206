const getWebflowPageWrapper = () => ({
  type: '@webflow/XscpData',
  payload: {
    nodes: [
      {
        _id: 'd24e5ea8-78da-f5b7-6dab-ac39945b55b0',
        tag: 'div',
        classes: [
          'b0934d9a-875a-bb42-0630-12090a77fc55',
        ],
        children: [],
        type: 'Block',
        data: {
          tag: 'div',
          text: false,
        },
      },
    ],
    styles: [
      {
        _id: 'b0934d9a-875a-bb42-0630-12090a77fc55',
        fake: false,
        type: 'class',
        name: 'page-wrapper',
        namespace: '',
        comb: '',
        styleLess: '',
        variants: {},
        children: [],
        selector: null,
      },
    ],
    assets: [],
    ix1: [],
    ix2: {
      interactions: [],
      events: [],
      actionLists: [],
    },
  },
  meta: {
    unlinkedSymbolCount: 0,
    droppedLinks: 0,
    dynBindRemovedCount: 0,
    dynListBindRemovedCount: 0,
    paginationRemovedCount: 0,
  },
});

export default getWebflowPageWrapper;
