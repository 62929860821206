export const debounce = (func, wait, immediate) => {
  let timeout;
  return function debounceFunc() {
    const context = this; const
      // eslint-disable-next-line prefer-rest-params
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function throttleFunc() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const handleKeyDownEnter = (cb) => (event) => {
  if (event.key === 'Enter') {
    cb(event);
  }
};

export const isBrowser = typeof window !== 'undefined';
